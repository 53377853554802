import React from 'react';
import { Divider } from '@/components/UI/Divider/Divider.styles';
import { Typography } from '@/components/UI/Typography/Typography.styles';
import { useTheme } from '@/hooks/theme/useTheme';
import { useTranslation } from 'react-i18next';
import { ChangeLang } from '@/components/ChangeLang/ChangeLang';
import { makeHrefForPhone } from '@/utils/helpers';
import { CONTACT_US } from '@/constants/contactUs';
import { ROUTES } from '@/constants/routes';
import { useBridgeState } from '@/bridge/hooks';
import NoSsr from '@/components/NoSsr/NoSsr';
import { CustomLink } from '@/components/CustomLink/CustomLink';
import * as S from './Footer.styles';

export const Footer = () => {
  const { t } = useTranslation();
  const { isDarkTheme } = useTheme();
  const { isWebView } = useBridgeState();

  if (isWebView) {
    return <S.FooterBox data-testid="footer" />;
  }

  return (
    <S.FooterBox data-testid="footer">
      <S.FooterLinks data-testid="footer-links">
        <Typography.Body as={CustomLink} href={ROUTES.about} target="_blank">
          {t('common.about_project')}
        </Typography.Body>
        <Typography.Body as={CustomLink} href={ROUTES.rules} target="_blank">
          {t('common.rules')}
        </Typography.Body>
        <Typography.Body as={CustomLink} href={ROUTES.terms} target="_blank">
          {t('common.terms_of_use')}
        </Typography.Body>
        <NoSsr>
          <Typography.Body
            as={CustomLink}
            href={ROUTES.advertising}
            target="_blank"
          >
            {t('common.place_advertisement')}
          </Typography.Body>
        </NoSsr>
      </S.FooterLinks>
      <Divider />

      <S.FooterPhones data-testid="footer-phones">
        <Typography.Numbers
          $variant="2"
          as="a"
          href={makeHrefForPhone(CONTACT_US.PHONE)}
        >
          {CONTACT_US.PHONE}
        </Typography.Numbers>
      </S.FooterPhones>
      <Typography.Caption>{t('common.working_hours')}</Typography.Caption>
      <S.FooterSocials data-testid="footer-socials">
        <S.FooterSocial
          href={`mailto:${CONTACT_US.EMAIL}`}
          target="_blank"
          $iconName={`mail${isDarkTheme ? '-dark' : ''}`}
          data-testid="mail-footer-social"
        />
        <S.FooterSocial
          href={CONTACT_US.FACEBOOK}
          target="_blank"
          $iconName={`facebook${isDarkTheme ? '-dark' : ''}`}
          data-testid="facebook-footer-social"
        />
        <S.FooterSocial
          href={CONTACT_US.INSTAGRAM}
          target="_blank"
          $iconName={`instagram${isDarkTheme ? '-dark' : ''}`}
          data-testid="instagram-footer-social"
        />
      </S.FooterSocials>

      <Divider />

      <ChangeLang />

      <S.FooterResponsibility data-testid="footer-responsibility">
        {t('common.administration_is_not_responsible')}
      </S.FooterResponsibility>
      <NoSsr>
        <Typography.Caption data-testid="footer-copyright">
          © 2008-{new Date().getFullYear()} Digital Classifieds MMC. <br />
          VÖEN: 1405631661
        </Typography.Caption>
      </NoSsr>
    </S.FooterBox>
  );
};
